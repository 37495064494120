import React, { useContext,useCallback, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { Loader } from '../components/Loader'
import { InjuredsList } from '../components/InjuredsList'
import { useNavigate } from "react-router-dom";
import { useMessage } from "../hooks/message.hook";

export const InjuredsPage = () =>{
    const[injured, setInjured] = useState([])
    const{loading, request} = useHttp()
    const{token} = useContext(AuthContext)
    // const navigate = useNavigate();
    const auth = useContext(AuthContext)
    const message = useMessage()

    const logoutHandler = () => {
        auth.logout()
    }

    const fetchInjureds = useCallback( async() =>{
        try{
            const fetched = await request('/api/injured', 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setInjured(fetched)
            
        } catch (e) {
            message(e.message)
            // logoutHandler()
        }
    },[token, request])
    useEffect (() => {
        fetchInjureds()
    }, [fetchInjureds])

    if (loading){
        return <Loader/>
    }
    
    
    return(
        <>

            {!loading && <InjuredsList injureds={injured}/>}
        </>
    )
}