import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { InjuredsPage } from "./pages/InjuredsPage";
import { NewInjured } from "./pages/NewInjured";
import { DetailPage } from "./pages/DetailPage";
import { AuthPage } from "./pages/AuthPage";
import { RegisterPage } from "./pages/RegisterPage";
import { StatisticPage } from "./pages/StatisticPage";

export const useRoutes = (isAuthenticated, userPermissions) => {
  if (isAuthenticated) {
    if((userPermissions.includes('statisticsViewer'))){
        return (
          <Routes>
            <Route exact path="/injureds" element={<InjuredsPage />} />
            <Route exact path="/statistic" element={<StatisticPage />} />
            <Route exact path="/newInjured" element={<NewInjured />} />
            <Route exact path="/detail/:id" element={<DetailPage />} />
            <Route path="*" element={<Navigate to="/newInjured" />} />
          </Routes>
        );
    }else{
      return (
        <Routes>
          <Route exact path="/injureds" element={<InjuredsPage />} />
          <Route exact path="/newInjured" element={<NewInjured />} />
          <Route exact path="/detail/:id" element={<DetailPage />} />
          <Route path="*" element={<Navigate to="/newInjured" />} />
        </Routes>
      );
      }
  } else {
  return (
    <Routes>
      <Route exact path="/" element={<AuthPage />} />
      <Route exact path="/register" element={<RegisterPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
  }
};
