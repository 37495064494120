import React from 'react'
import 'materialize-css'
import { BrowserRouter as Router } from 'react-router-dom'
import { useRoutes } from './routes'
import { useAuth } from './hooks/auth.hook'
import { AuthContext } from './context/AuthContext'
import { Navbar } from './components/Navbar'
import { Loader } from './components/Loader'
import { jwtDecode } from 'jwt-decode'
import 'materialize-css/dist/css/materialize.min.css';

function App() {
  const {token, login, logout, userId, ready , userPermissions, tokenExpiredTime} = useAuth()
  const isAuthenticated = !!token
  const routes = useRoutes(isAuthenticated, userPermissions)
  if (!ready) {
    return  <Loader/>
  }

  const checTokenAlive = () => {
  if (!token){
    return ('')
  }
  if(Date.now()/1000 > jwtDecode(token).exp-600){
    logout()
  }
}


  return (
    <AuthContext.Provider value = {{
      token, login, logout, userId, isAuthenticated, userPermissions, tokenExpiredTime
    }}>
      <Router>
        {checTokenAlive()}
        { isAuthenticated && <Navbar /> }
        <div className='container'>
          {routes}
        </div>
      </Router>
    </AuthContext.Provider>
  )
}

export default App;
