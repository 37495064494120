import React, { useCallback, useContext, useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import { Loader } from '../components/Loader'
import { LinkCard } from '../components/LinkCard'

export const DetailPage = () =>{

    const {token} = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [injured, setInjured] = useState(null)
    const injuredId = useParams().id

    const getInjured = useCallback(async () =>{
        try{
            const fetched = await request(`/api/injured/${injuredId}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setInjured(fetched)
        }catch(e){}
    },[token, injuredId, request])
    useEffect(()=>{
        getInjured()
    },[getInjured])

    if(loading){
        return <Loader/>
    }

    return(
    <>
      { !loading && injured && <LinkCard injured={injured.injureds} owner={injured.owner}  /> }
    </>
    )
}