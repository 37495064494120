import React from "react";
import { useState } from "react";
import { SwitchComponent } from "./InjuredsSwitchInput";

// import './ControlBlock.css';

const numbers = ["First","Second","Third","Fourth","Fifth","Sixth","Seventh","Eighth", "Ninth", "Tenth", "Eleventh", "Twelfth"]
const informationsArray = []

const injuredFormTrauma = []
const allBloodControlInputs = []
const allAirwayInputs = []
const allRespirationInputs = []
const allIntravenousAccessInputs = []
const allCirculationBloodInputs = []
const allCirculationFluidInputs = []
const allMedicationInputs = []
const otherInputs = []
const vitalSigns = []

// vitalSigns
for (let i=0; i < 4;i++){
  vitalSigns.push( 
    [
      {
        label: "Час *",
        fieldName: "vitalSignTime" + numbers[i],
        type: "input",
        dataType: "time",
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Пульс *",
        fieldName: "vitalSignPulse" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Сатурація *",
        fieldName: "vitalSignSaturation" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Тиск",
        fieldName: "vitalSignPresue" + numbers[i],
        type: "input",
      },
      {
        label: "Частота дихання",
        fieldName: "vitalSignBreathingFR" + numbers[i],
        type: "input",
      },
      {
        label: "AVPU *",
        fieldName: "vitalSignAVPU" + numbers[i],
        type: "select",
        arrayOptions:['A - реагує на все',
                      'V - голос',
                      'P - біль',
                      'U - без свідомості',
                  ],
        rules: { required: "Поле обов`язкове" },
      }
    ]
   )
}
for (let i=0; i < 6;i++){
  injuredFormTrauma.push(
    [
      {
        label: "Механізм ураження *",
        fieldName: "traumaMechanismOfInjury" + numbers[i],
        type: "select",
        arrayOptions: ['Осколки/шрапнель',
                      'ВП – вогнепальне поранення',
                      'Вибух – міномет/артилерія/ракет',
                      'Вибух – демонтований СВП або міна',
                      'Вибух – монтований СВП або міна',
                      'Вибух – РПГ або граната',
                      'Вибух – інше',
                      'Дрони - FPV/Скид',
                      'Авіація - Бомба/Ракета',                    
                      'ДТП/Зіткнення',
                      'Руйнування будівлі',
                      'Вогонь/вибух',
                      'Падіння',
                      'Навколишнє середовище',
                      'Хімічні засоби',
                      'Аварія літального апарата',
                      'Інше',
                  ],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Інформація про поранення *",
        fieldName: "traumaInjury" + numbers[i],
        type: "select",
        arrayOptions: ['Ампутація',
                      'Кровотеча',
                      'Опік',
                      'Обмороження',
                      'Крепітація',
                      'Деформація',
                      'Відрив шкіри',
                      'Перелом',
                      'Вогнепальне поранення',
                      'Гематома',
                      'Рвана рана',
                      'Біль',
                      'Колота рана',
                      'Отруєння газами',
                      'Множинні дрібні травми',
                      'Підозра на ЗЧМТ',
                      'Інше'],
        rules: { required: "Поле обов`язкове" },
      },
      
      {
        label: "Місце травми *",
        fieldName: "injuryPlaceFirst" + numbers[i],
        type: "select",
        arrayOptions:['Голова',
                      'Ліва рука',
                      'Права рука',
                      'Ліва нога',
                      'Права нога',
                      'Нижня частина тулуб',
                      'Верхня частина тулуба',
                  ],
        rules: { required: "Поле обов`язкове" },
      },
    ]
   )
}
for (let i=0; i < 6;i++){
  allBloodControlInputs.push(
  [
    {
      label: "Ким виконана маніпуляція *",
      fieldName: "bCWhoPerformed" + numbers[i],
      type: "select",
      arrayOptions: ["Екіпаж", "До приїзду", "Інші"],
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Час накладання *",
      fieldName: "bCTime" + numbers[i],
      type: "input",
      dataType: "time",
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Місце накладання *",
      fieldName: "bCLocation" + numbers[i],
      type: "select",
      arrayOptions: [
        "Голова",
        "Ліва рука",
        "Права рука",
        "Ліва нога",
        "Права нога",
        "Нижня частина тулуба",
        "Верхня частина тулуба",
      ],
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Тип контролю *",
      fieldName: "bCType" + numbers[i],
      type: "select",
      arrayOptions: [
        "Джгут",
        "Тампонування",
        "Тиснуча пов'язка",
        "Вузловий турнікет",
        "Аортальний турнікет (AAJT-S)",
        "ITClamp",
        "Прямий тиск",
      ],
      rules: { required: "Поле обов`язкове" },
    },
  ]
)
}
for (let i=0; i < 4;i++){
  allAirwayInputs.push(
    [
      {
        label: "Ким виконано *",
        fieldName: "AirwayWhoPerformed" + numbers[i],
        type: "select",
        arrayOptions: ['Екіпаж',
                      'До приїзду',
                      'Інші'],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Час встановлення *",
        fieldName: "AirwayTime" + numbers[i],
        type: "input",
        dataType: "time",
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Тип пристрою *",
        fieldName: "AirwayType" + numbers[i],
        type: "select",
        arrayOptions:['NPA',
                      'Орофаренгіальний',
                      'Igel',
                      'Ларингіальна маска',
                      'Combitube',
                      'ETT', ],
        rules: { required: "Поле обов`язкове" },
      },
      
      {
        label: "Розмір/поділка *",
        fieldName: "AirwaySize" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
    ]
  )
}
for (let i=0; i < 4;i++){
  allRespirationInputs.push(
    [
      {
          label: "Ким виконано *",
          fieldName: "respirationkWhoPerformed"  + numbers[i],
          type: "select",
          arrayOptions:['Екіпаж',
                      'До приїзду',
                      'Інші'],
          rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Тип маніпуляції *",
        fieldName: "respirationManipulationType" + numbers[i],
        type: "select",
        arrayOptions:['Оклюзійна наліпка',
                      'Декомпресійна голка',
                      'Дренаж',],
        rules: { required: "Поле обов`язкове" },
      },
    ]
  )
}
for (let i=0; i < 4;i++){
  allIntravenousAccessInputs.push(
    [
      {
        label: "Ким виконано *",
        fieldName: "IVWhoPerformed"  + numbers[i],
        type: "select",
        arrayOptions: ['Екіпаж',
                      'До приїзду',
                      'Інші'],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Тип доступу *",
        fieldName: "IVType"  + numbers[i],
        type: "select",
        arrayOptions: ['Внутрішньовенний',
                      'Внутрішньокістковий',
                      'Центральна вена',
                      'Артеріальний',],
        rules: { required: "Поле обов`язкове" },
      }
    ]
  )
}
for (let i=0; i < 3;i++){
    allCirculationBloodInputs.push(
      [
        {
          label: "Ким виконано *",
          fieldName: "circulationBloodPerformed"  + numbers[i],
          type: "select",
          arrayOptions: ['Екіпаж',
                        'До приїзду',
                        'Інші'],
          rules: { required: "Поле обов`язкове" },
        },
        {
          label: "Компонент *",
          fieldName: "circulationBloodType"  + numbers[i],
          type: "input",
          rules: { required: "Поле обов`язкове" },
        }
      ]
    )
}
for (let i=0; i < 7; i++) {
  allCirculationFluidInputs.push(
    [
      {
        label: "Ким виконано *",
        fieldName: "fluidWhoPerformed" + numbers[i],
        type: "select",
        arrayOptions: ["Екіпаж", "До приїзду", "Інші"],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Назва *",
        fieldName: "fluidName" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Шлях введення*",
        fieldName: "fluidRoute" + numbers[i],
        type: "select",
        arrayOptions: [
          "В/В",
          "В/М",
          "В/К",
          "Перорально",
          "Під язик",
          "Під шкіру",
          "Інгаляційно",
          "Ректально",
        ],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Об'єм/Дозування*",
        fieldName: "fluidDose" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
    ],
  );
}
for (let i=0; i < 12; i++) {
  allMedicationInputs.push(
    [
      {
        label: "Ким виконано*",
        fieldName: "medicationsWhoPerformed" + numbers[i],
        type: "select",
        arrayOptions: ["Екіпаж", "До приїзду", "Інші"],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Назва*",
        fieldName: "medicationsName" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Шлях введення*",
        fieldName: "medicationsRoute" + numbers[i],
        type: "select",
        arrayOptions: [
          "В/В",
          "В/М",
          "В/К",
          "Перорально",
          "Під язик",
          "Під шкіру",
          "Інгаляційно",
          "Ректально",
        ],
        rules: { required: "Поле обов`язкове" },
      },
      {
        label: "Об'єм*",
        fieldName: "medicationsDose" + numbers[i],
        type: "input",
        rules: { required: "Поле обов`язкове" },
      },
    ]);
}
for (let i=0; i < 1;i++){
  otherInputs.push(
    [
      {
        label: "Час встановлення сечового катетера",
        fieldName: "foleyTime"  + numbers[i],
        type: "input",
        dataType: "time",
      },
      {
        label: "Гастральний зонд",
        fieldName: "gastricTube"  + numbers[i],
        type: "select",
        arrayOptions: ['Оральний',
                      'Назальний',
                       ],
      },
      {
        label: "Захист очей (щиток на око)",
        fieldName: "eyeProtection"  + numbers[i],
        type: "select",
        arrayOptions: ['Ліве око',
                      'Праве око',
                      'Обидва ока',
                       ],
      },
      {
        label: "Імобілізація (Місця імобілізації)",
        fieldName: "immobilization"  + numbers[i],
        type: "input",
      },
      {
        label: "Попереження гіпотермії (засіб)",
        fieldName: "preventionOfHypothermia"  + numbers[i],
        type: "input",
      },
      {
        label: "Коментарі/Нотатки",
        fieldName: "notes"  + numbers[i],
        type: "textarea",
      },
    ]
  )
}


const switchElment = ({index, setIndex, resetField, errors, blockType}) => {
  switch (blockType) {
    case 'bloodControl':
      return(
      renderElments(index, setIndex, resetField, errors, allBloodControlInputs, BleedControllTable, "кровотеч")
      )
    case 'airway':

      return(
        renderElments(index, setIndex, resetField, errors, allAirwayInputs, AirvayControllTable, "дихальних шляхів" )
        )
    case 'respiration':
      return(
        renderElments(index, setIndex, resetField, errors, allRespirationInputs, RespirationControllTable, 'дихання')
        )
    case 'intravenousAccess':
      return(
        renderElments(index, setIndex, resetField, errors, allIntravenousAccessInputs, IVControllTable, 'внутрішньовенного доступу')
        )
    case 'circulationBlood':
      return(
        renderElments(index, setIndex, resetField, errors, allCirculationBloodInputs, CirculationBloodControllTable, 'крові')
        )
    case 'circulationFliud':
      return(
        renderElments(index, setIndex, resetField, errors, allCirculationFluidInputs, CirculationInfusionTerapyControllTable, 'інфузійної терапії')
        )
    case 'medication':
      return(
        renderElments(index, setIndex, resetField, errors, allMedicationInputs, MedicationControllTable, 'медикаментів')
        )
    case 'injuredFormTrauma':
      return(
        renderElments(index, setIndex, resetField, errors, injuredFormTrauma, injuredFormTraumaTable, 'травми')
        )
    case 'other':
      return(
        renderElments(index, setIndex, resetField, errors, otherInputs, otherInputsTable, 'інше')
      )
    case 'vitalSigns':
        return(
          renderElments(index, setIndex, resetField, errors, vitalSigns, vitalSignsTable, 'показників')
        )
    default:
      return
  }
}

const renderElments = (index, setIndex, resetField, errors, informationsArray, tableType, blocktext) => {

  switch (index) {
    case 0:
      return (
        <>
          <div>
            <button
              className="waves-light btn"
              onClick={() => setIndex((prevIndex) => prevIndex + 1)}
            >
              Додати блок {blocktext}
            </button>
          </div>
        </>
      );
    case informationsArray.length:
      return (
        <>
        <div>
          {informationsArray.slice(0, index).map((x) => (
            tableType(x, errors)
          ))}
          <button
            style={{ background: "red" }}
            className="waves-light btn"
            onClick={() => {
              setIndex((prevIndex) => prevIndex - 1);
              informationsArray
                .slice(index - 1, informationsArray.length)
                .map((x) => x.map((y) => resetField(y.fieldName)));
            }}
          >
            Видалити блок {blocktext}
          </button>
        </div>
        </>
      );
    default:
      return (
        <>
        <div>
          {informationsArray.slice(0, index).map((x) => (
            tableType(x, errors)
          ))}
          <div/>
          <p>
            <button
              className="waves-light btn"
              onClick={() => setIndex((prevIndex) => prevIndex + 1)}
            >
              Додати блок {blocktext}
            </button>
          </p>
          <p>
            <button
              style={{ background: "red" }}
              className="waves-light btn"
              onClick={() => {
                setIndex((prevIndex) => prevIndex - 1);
                informationsArray
                  .slice(index - 1, informationsArray.length)
                  .map((x) => x.map((y) => resetField(y.fieldName)));
              }}
            >
              Видалити блок {blocktext}
            </button>
          </p>
        </div>
        </>
      );
  }
};

const BleedControllTable = ( BCarray, errors ) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>

        <tr>
          <td>{SwitchComponent(BCarray[2], errors)}</td>
          <td>{SwitchComponent(BCarray[3], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const AirvayControllTable = ( BCarray, errors ) => {

  return (
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>

        <tr>
          <td>{SwitchComponent(BCarray[2], errors)}</td>
          <td>{SwitchComponent(BCarray[3], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const RespirationControllTable = ( BCarray, errors ) => {
  return (
    <table>
      <tbody > 
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const IVControllTable = ( BCarray, errors ) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const CirculationBloodControllTable = ( BCarray, errors ) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const CirculationInfusionTerapyControllTable = ( BCarray, errors ) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>

        <tr>
          <td>{SwitchComponent(BCarray[2], errors)}</td>
          <td>{SwitchComponent(BCarray[3], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const MedicationControllTable = ( BCarray, errors ) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>

        <tr>
          <td>{SwitchComponent(BCarray[2], errors)}</td>
          <td>{SwitchComponent(BCarray[3], errors)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const injuredFormTraumaTable = ( BCarray, errors ) => {
  return (<table className="bordered">
     <tbody>
     <tr> 
     <td>
    {SwitchComponent(BCarray[0], errors)}
    {SwitchComponent(BCarray[1], errors)}
    {SwitchComponent(BCarray[2], errors)}
    </td>
    </tr> 
      </tbody>
    </table>)
};

const otherInputsTable = ( BCarray, errors ) => {
  return (<>
    {SwitchComponent(BCarray[0], errors)}
    {SwitchComponent(BCarray[1], errors)}
    {SwitchComponent(BCarray[2], errors)}
    {SwitchComponent(BCarray[3], errors)}
    {SwitchComponent(BCarray[4], errors)}
    {SwitchComponent(BCarray[5], errors)}
    </>);
};


const vitalSignsTable = ( BCarray, errors ) => {
  return (
    <table className="bordered">
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>

        <tr>
          <td>{SwitchComponent(BCarray[2], errors)}</td>
          <td>{SwitchComponent(BCarray[3], errors)}</td>
        </tr>
        <tr>
          <td>{SwitchComponent(BCarray[4], errors)}</td>
          <td>{SwitchComponent(BCarray[5], errors)}</td>
        </tr> 
      </tbody>
    </table>
  );
};

export const ControlBlock = ({ resetField, errors, blockType }) => {
  const [index, setIndex] = useState(0);
  return switchElment({index, setIndex, resetField, errors, blockType, informationsArray});
};

export const ArrayNamesBlock = new Map([
  ['vitalSigns', vitalSigns],
  ['injuredFormTrauma',injuredFormTrauma],
  ['allAirwayInputs',allAirwayInputs],
  ['allRespirationInputs', allRespirationInputs],
  ['allIntravenousAccessInputs', allIntravenousAccessInputs],
  ['allCirculationBloodInputs', allCirculationBloodInputs],
  ['allBloodControlInputs', allBloodControlInputs],
  ['allCirculationFluidInputs', allCirculationFluidInputs],
  ['allMedicationInputs', allMedicationInputs],
  ['otherInputs', otherInputs],
]);

// ({vitalSigns,injuredFormTrauma,allAirwayInputs,allRespirationInputs,
//     allIntravenousAccessInputs,allCirculationBloodInputs, 
//     allBloodControlInputs,allCirculationFluidInputs,allMedicationInputs,
//     otherInputs})
export const ArrayNumbers = (numbers)