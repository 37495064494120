import './NewInjured.css';
import React, {useContext} from 'react' 
import {useHttp} from '../hooks/http.hook'

// import { jwtDecode } from 'jwt-decode'
// import { jsonwebtoken } from 'jsonwebtoken';
import {AuthContext} from '../context/AuthContext'
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { InjuredList, SwitchComponent } from "../components/InjuredsSwitchInput";
import { ControlBlock, ArrayNamesBlock} from '../components/ControlBlock';
// import { breadcrumbsClasses, getContrastRatio } from '@mui/material';

// ArrayNamesBlock = {ArrayNamesBlock}
export const NewInjured = () => {
  const auth = useContext(AuthContext)
  const {request} = useHttp()
  // const{token} = useContext(AuthContext)
  // const jwt = require('jsonwebtoken')
  const navigate = useNavigate();
  const logoutHandler = () => {
    auth.logout()
  }
  // const fetchInjureds = useCallback( async() =>{
  //   try{
  //       const fetched = await request('/api/injured', 'GET', null, {
  //           Authorization: `Bearer ${token}`
  //       })        
  //   } catch (e) {
  //       logoutHandler()
  //   }
  //   },[token, request])
  //   useEffect (() => {
  //       fetchInjureds()
  //   }, [fetchInjureds])

  

  const pressHandler = async () =>{
    const sendDate = createDataRequest()
    try {
      const data = await request('/api/injured/generate', 'POST', sendDate, {
        Authorization: `Bearer ${auth.token}`
      })
      
      navigate(`/detail/${data.injured._id}`)
      // history.push(`/detail/${data.injured._id}`)
    } catch (e) {
      logoutHandler()
    }
      // try{
      //     const data = await request('api/injured/generate', 'POST', {
      //     }, {
      //         Authorization: `Bearer ${token}`
      //     })
      //     // // navigate(`/detail/${data.link._id}`)
      // } catch (e) {}
  }
  const methods = useForm({ mode: "onBlur" });
  const {
    resetField,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const onRegister = async (data) => {
    // (data);
    pressHandler()

  };

  const searchNames = (arrname) => {
    const result = []
    arrname.forEach((element) => result.push({NameField: element.fieldName, ValueField:getValues(element.fieldName), TextField: element.label }))
    return(result)
  }

  const createDataRequest = () => {
    // const requestData = new Map()
    const requestData = {}
    for(const fruit of allArraysName.keys()){
      requestData[fruit] = searchNames(allArraysName.get(fruit)) 
    }

    for(const vbn of ArrayNamesBlock.keys()){

      const temp = []
      // requestData[vbn] = searchNames(ArrayNamesBlock.get(vbn)) 
      ArrayNamesBlock.get(vbn).forEach((elem) => temp.push(searchNames(elem)))
      requestData[vbn] = temp
    }
    return requestData
  }

  const injuredFormEvent = [
    {
      label: "Дата поранення *",
      fieldName: "eventDate",
      type: "input",
      dataType: "date",
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Час поранення",
      fieldName: "evenTime",
      type: "input",
      dataType: "time",
    },
    {
      label: "Місце де отримано пораннення (приблизна локація)",
      fieldName: "evenText",
      type: "input",
      dataType: "text",
    },
  ];
  const injuredFormEvacuation = [
    {
      label: "Етап надання допомоги *",
      fieldName: "evacuationType",
      type: "select",
      arrayOptions: ['В польових умовах', 'Кейсевак', 'Медевак', 'Стабілізаційний пункт'],
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Тип евакуації *",
      fieldName: "evacuationMethodOfTransporting",
      type: "select",
      arrayOptions: ['Невідкладна', 'Пріоритетна', 'Звичайна'],
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Підрозділ від якого отримали пораненого *",
      fieldName: "evacuationUnitThatHandedTheInjured",
      type: "input",
      dataType: "text",
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Дата отримання пораненого *",
      fieldName: "evacuationDateThatHandedTheInjured",
      type: "input",
      dataType: "date",
      rules: { required: "Поле обов`язкове" },
    },
    {
        label: "Час отримання пораненого *",
        fieldName: "evacuationTimeThatHandedTheInjured",
        type: "input",
        dataType: "time",
        rules: { required: "Поле обов`язкове" },
      },
    {
      label: "Підрозділ якому передали пораненого *",
      fieldName: "evacuationUnitThatReceivedTheInjured",
      type: "input",
      dataType: "text",
      rules: { required: "Поле обов`язкове" },
    },
    {
        label: "Дата передачі пораненого *",
        fieldName: "evacuatioDateTransferInjured",
        type: "input",
        dataType: "date",
        rules: { required: "Поле обов`язкове" },
      },
    {
      label: "Час передачі пораненого *",
      fieldName: "evacuationTimeTransferInjured",
      type: "input",
      dataType: "time",
      rules: { required: "Поле обов`язкове" },
    },
  ];
  const injuredFormInjuredInformation = [
    {
      label: "ПІБ *",
      fieldName: "injuredName",
      type: "input",
      rules: { required: "Поле обов`язкове" },
    },
    // {
    //   label: "Гендер",
    //   fieldName: "injuredgender",
    //   type: "select",
    //   arrayOptions: ['Чоловік', 'Жінка', 'бойовий вертоліт','пес', 'АвГУсТ', 'Мені здається він прикидається під августа'],
    //   rules: { required: "Поле обов`язкове" },
    // },
    {
      label: "Стать *",
      fieldName: "injuredSex",
      type: "select",
      arrayOptions: ['Чоловік', 'Жінка'],
      rules: { required: "Поле обов`язкове" },
    },
    {
      label: "Дата народження",
      fieldName: "injuredBirthDate",
      type: "input",
      dataType: "date",
    },
    {
      label: "Підрозділ",
      fieldName: "injuredUnit",
      type: "input",
    },
    {
      label: "Званння",
      fieldName: "injuredRank",
      type: "input",
    },
    {
      label: "ІПН/Військовий квиток",
      fieldName: "injuredLastFour",
      type: "input",
    },
    {
      label: "Алергії",
      fieldName: "injuredAllergies",
      type: "input",
    },
    {
      label: "Інше",
      fieldName: "injuredOther",
      type: "input",
    },
  ];
  // const injuredFormTrauma = [
  //   {
  //     label: "Механізм ураження *",
  //     fieldName: "traumaMechanismOfInjury",
  //     type: "select",
  //     arrayOptions: ['Осколки/шрапнель',
  //                   'ВП – вогнепальне поранення',
  //                   'Вибух – міномет/артилерія/ракет',
  //                   'Вибух – демонтований СВП або міна',
  //                   'Вибух – монтований СВП або міна',
  //                   'Вибух – РПГ або граната',
  //                   'Вибух – інше',
  //                   'Дрони - FPV/Скид',
  //                   'Авіація - Бомба/Ракета',                    
  //                   'ДТП/Зіткнення',
  //                   'Руйнування будівлі',
  //                   'Вогонь/вибух',
  //                   'Падіння',
  //                   'Навколишнє середовище',
  //                   'Хімічні засоби',
  //                   'Аварія літального апарата',
  //                   'Інше',
  //               ],
  //     rules: { required: "Поле обов`язкове" },
  //   },
  //   {
  //     label: "Інформація про поранення *",
  //     fieldName: "traumaInjury",
  //     type: "select",
  //     arrayOptions: ['Ампутація',
  //                   'Кровотеча',
  //                   'Опік',
  //                   'Крепітація',
  //                   'Деформація',
  //                   'Відрив шкіри',
  //                   'Перелом',
  //                   'Вогнепальне поранення',
  //                   'Гематома',
  //                   'Рвана рана',
  //                   'Біль',
  //                   'Колота рана',
  //                   'Отруєння газами',
  //                   'Множинні дрібні травми',
  //                   'Інше'],
  //     rules: { required: "Поле обов`язкове" },
  //   },
    
  //   {
  //     label: "Місце травми *",
  //     fieldName: "injuryPlaceFirst",
  //     type: "select",
  //     arrayOptions:['Голова',
  //                   'Ліва рука',
  //                   'Права рука',
  //                   'Ліва нога',
  //                   'Права нога',
  //                   'Нижня частина тулуб',
  //                   'Верхня частина тулуба',
  //               ],
  //     rules: { required: "Поле обов`язкове" },
  //   },
  // ];
  const injuredFormRespiration = [
    {
      label: "Тип дихання *",
      fieldName: "RespirationType",
      type: "select",
      arrayOptions:['Природнє',
                    'Ускладненне',
                    'З допомогою',
                    'Агональне',],
      rules: { required: "Поле обов`язкове" },
      },
    {
      label: "Рух грудної клітки *",
      fieldName: "bCTime",
      type: "select",
      arrayOptions:['Симетричне',
                    'Несиметричне',
                    'Неможливо визначити',],
      rules: { required: "Поле обов`язкове" },
    },
  ];
  const squadInfo = [
    {
      label: "Номер екіпажу *",
      fieldName: "squadNumber",
      type: "input",
      rules: { required: "Поле обов`язкове" },
    }
  ];

  
const allArraysName = new Map([
  ['injuredFormEvent', injuredFormEvent],
  ['injuredFormEvacuation',injuredFormEvacuation],
  ['injuredFormInjuredInformation',injuredFormInjuredInformation],
  // ['injuredFormTrauma', injuredFormTrauma],
  ['injuredFormRespiration', injuredFormRespiration],
  ['squadInfo', squadInfo]
])


const injuredFormEventTable = ( BCarray, errors ) => {

  return (
    <div className='injuredTable'>
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>
        <tr>
          <td colSpan="2">{SwitchComponent(BCarray[2], errors)}</td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

const injuredFormEvacuationtTable = ( BCarray, errors ) => {

  return (
    <div className='injuredTable'>
    <table>
      <tbody>
        <tr>
          <td colSpan="2">{SwitchComponent(BCarray[0], errors)}</td>
        </tr>
        <tr>
          <td colSpan="2">{SwitchComponent(BCarray[1], errors)}</td>
        </tr>
        <tr>
          <td colSpan="2">{SwitchComponent(BCarray[2], errors)}</td>
        </tr>
        <tr>
          <td>{SwitchComponent(BCarray[3], errors)}</td>
          <td>{SwitchComponent(BCarray[4], errors)}</td>
        </tr>
        <tr>
          <td colSpan="2">{SwitchComponent(BCarray[5], errors)}</td>
        </tr>
        <tr>
          <td>{SwitchComponent(BCarray[6], errors)}</td>
          <td>{SwitchComponent(BCarray[7], errors)}</td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

const injuredFormRespirationTable = ( BCarray, errors ) => {

  return (
    <div className='injuredTable'>
    <table>
      <tbody>
        <tr>
          <td>{SwitchComponent(BCarray[0], errors)}</td>
          <td>{SwitchComponent(BCarray[1], errors)}</td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};
// const simple = () =>{
//   if(auth.userPermissions.includes('statisticsViewer'))
//     return (<h1>ХЕХ</h1>)
// } 
 
  return (
    <>
    <div className="col s8 offset-s2" style={{ paddingTop: "2rem" }}>
    {/* {simple()} */}
      <FormProvider {...methods}>
        
        {/* <ControlBlock resetField = {resetField} errors = {errors}/> */}
       {/* <InjuredList informationsArray = {injuredFormRespiration} errors = {errors}/> */}

        <h1>Звіт про надану допомогу</h1>
        <h6>Поля відмічені * обовязкові до заповнення</h6>
        <h5>Подія</h5>
        {injuredFormEventTable(injuredFormEvent, errors) } 

        <h5>Евакуація</h5>
        {injuredFormEvacuationtTable(injuredFormEvacuation, errors)}
        <h5>Дані пораненого</h5>
        <InjuredList informationsArray = {injuredFormInjuredInformation} errors = {errors}/>

        <h5>Травма</h5>
        {/* <InjuredList informationsArray = {injuredFormTrauma } errors = {errors}/> */}
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'injuredFormTrauma'}/>  

        <h5>Життєві показники</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'vitalSigns'}/> 

        <h5>Контроль кровотеч</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'bloodControl'}/>  
        <h5>Дихальні шляхи</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'airway'}/>  

        <h5>Дихання</h5>
        {injuredFormRespirationTable(injuredFormRespiration, errors)}
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'respiration'}/>  


        <h5>Внутрішньовенний доступ</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'intravenousAccess'}/>  

        <h5>Циркуляція - Кров</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'circulationBlood'}/>  

        <h5>Циркуляція - Інфузійна терапія</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'circulationFliud'}/>  

        <h5>Медикаменти</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'medication'}/>  

        <h5>Інше</h5>
        <ControlBlock resetField = {resetField} errors = {errors} blockType={'other'}/>  

        <h5>Хто заповняв форму</h5>
        <InjuredList informationsArray = {squadInfo} errors = {errors}/> 
        
        <button className=" btn-large" onClick={handleSubmit(onRegister)}>Відправити</button>
      </FormProvider>
    </div>
    </> 
  );
};
