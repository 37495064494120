// import React, { useContext,useCallback, useEffect, useState } from 'react'



const outputInfo = (testArr, key) =>{
    switch(key){
      case 'injuredFormEvent':
        return(
          <><div>
          {testArr[key].map((elem) =>{
            if(!elem.ValueField){return}
            return(<div><b>{elem.TextField + ': '}</b>  {elem.ValueField}</div>)
           })}
          </div></>
          )
        case 'injuredFormEvacuation':
          return(
          <><div>
          {testArr[key].map((elem) =>{
            if(!elem.ValueField){return}
            return(<div><b>{elem.TextField + ': '}</b>  {elem.ValueField}</div>)
           })}
          </div></>
          )
        case 'injuredFormInjuredInformation':
        return(
          <><div>
          {testArr[key].map((elem) =>{
            if(!elem.ValueField){return}
            return(<div><b>{elem.TextField + ': '}</b>  {elem.ValueField}</div>)
           })}
          </div></>
          )


        case 'injuredFormTrauma':
          return(
            <><div>
            {testArr[key].map((elem) =>(
              elem.map((eeelll)=>{
              if(!eeelll.ValueField){return}
              return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
            })))}
            </div></>
            )


        case 'squadInfo':
        return(
          <><div>
          {testArr[key].map((elem) =>{
            if(!elem.ValueField){return}
            return(<div><b>{elem.TextField + ': '}</b>  {elem.ValueField}</div>)
           })}
          </div></>
          )
        case 'injuredFormRespiration':
          return(
          <><div>
          {testArr[key].map((elem) =>{
            if(!elem.ValueField){return}
            return(<div><b>{elem.TextField + ': '}</b>  {elem.ValueField}</div>)
           })}
          </div></>
          )

        
        case 'allAirwayInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'allBloodControlInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'allCirculationBloodInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'allCirculationFluidInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'allIntravenousAccessInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'allMedicationInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'allRespirationInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'injuryPlace':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'otherInputs':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )
        case 'vitalSigns':
          return(
          <><div>
          {testArr[key].map((elem) =>(
            elem.map((eeelll)=>{
            if(!eeelll.ValueField){return}
            return(<div><b>{eeelll.TextField + ': '}</b>  {eeelll.ValueField}</div>)
          })))}
          </div></>
          )   
  }
}


export const LinkCard = ({ injured, owner }) => {
  return (
    <>
      <h3>Інформація про пораненого</h3>

      <h5>Подія</h5>
      {outputInfo(injured, 'injuredFormEvent')}
      <h5>Евакуація</h5>
      {outputInfo(injured, 'injuredFormEvacuation')}
      <h5>Дані пораненого</h5>
      {outputInfo(injured, 'injuredFormInjuredInformation')}
      <h5>Травма</h5>
      {outputInfo(injured, 'injuredFormTrauma')}
      {outputInfo(injured, 'injuryPlace')}
      <h5>Життєві показники</h5>
      {outputInfo(injured, 'vitalSigns')}
      <h5>Контроль кровотеч</h5>
      {outputInfo(injured, 'allBloodControlInputs')}
      <h5>Дихальні шляхи</h5>
      {outputInfo(injured, 'allAirwayInputs')}
      <h5>Дихання</h5>
      {outputInfo(injured, 'injuredFormRespiration')}
      {outputInfo(injured, 'allRespirationInputs')}
      <h5>Внутрішньовенний доступ</h5>
      {outputInfo(injured, 'allIntravenousAccessInputs')}
      <h5>Циркуляція - Кров</h5>
      {outputInfo(injured, 'allCirculationBloodInputs')}
      <h5>Циркуляція - Інфузійна терапія</h5>
      {outputInfo(injured, 'allCirculationFluidInputs')}
      <h5>Медикаменти</h5>
      {outputInfo(injured, 'allMedicationInputs')}
      <h5>Інше</h5>
      {outputInfo(injured, 'otherInputs')}
      <h5>Хто заповняв форму</h5>
      {/* {outputInfo(injured, 'injuredFormEvent')} */}




      
      {/* <p>Ваша ссылка: <a href={link.to} target="_blank" rel="noopener noreferrer">{link.to}</a></p>
      <p>Откуда: <a href={link.from} target="_blank" rel="noopener noreferrer">{link.from}</a></p>
      <p>Количество кликов по ссылке: <strong>{link.clicks}</strong></p> */}

      {/* <div>Дата поранення: {injured.eventDate}</div>
      <div>Час поранення: {injured.eventTime}</div>
      <div>Місце поранення: {injured.eventPlace}</div>
      <div>Тип евакуації: {injured.evacuationType}</div>
      <div>Метод транспортування: {injured.evacuationMethodOfTransporting}</div>
      <div>Підрозділ від якого прийняли пораненого: {injured.evacuationUnitThatHandedTheInjured}</div>
      <div>Час отримання пораненого: {injured.evacuationTimeThatHandedTheInjured}</div>
      <div>Підрозділ якому передали пораненого: {injured.evacuationUnitThatReceivedTheInjured}</div>
      <div>Час передачі пораненого: {injured.evacuationTimeThatReceivedTheInjured}</div>

      <h5>Інформація про пораненого</h5>
      <div>Ім'я : {injured.injuredName}</div>
      <div>Стать: {injured.injuredSex}</div>
      <div>Підрозділ: {injured.injuredUnit}</div>
      <div>Звання: {injured.injuredRank}</div>
      <div>Військовий квиток/IПН: {injured.injuredLastFour}</div>
      <div>Алергії: {injured.injuredAllergies}</div>
      <div>Інше: {injured.injuredOther}</div>

      <h5>Інформація про поранення</h5>
      <div>Механізм ураження: {injured.traumaMechanismOfInjury}</div>
      <div>Травма: {injured.traumaInjury}</div>
      <div>Місце поранення: {injured.traumaAnnotateInjuries}</div>

      <h5>Масивні кровотечі</h5>
      <div>Хто виконав: {injured.bCWhoPerformed}</div>
      <div>Час: {injured.bCTime}</div>
      <div>Місце: {injured.bCLocation}</div>
      <div>Тип контролю: {injured.bCType}</div>

      <h5>Дихальні шляхи</h5>
      <div>Хто виконав: {injured.airwayWhoPerformed}</div>
      <div>Час: {injured.airwayTime}</div>
      <div>Тип: {injured.airwayType}</div>
      <div>Розмір: {injured.airwaySize}</div>

      <h5>Дихання/Респірація</h5>
      <div>Тип дихання: {injured.respirationType}</div>
      <div>Рух грудної клітки: {injured.respirationChest}</div>
      <div>Хто виконав: {injured.respirationkWhoPerformed}</div>
      <div>Тип маніпуляції: {injured.respirationManipulationType}</div>

      <h5>В/В доступ</h5>
      <div>Хто виконав: {injured.IVWhoPerformed}</div>
      <div>Тип доступу: {injured.IVType}</div>

      <h5>Кров</h5>
      <div>Ким виконано: {injured.blodWhoPerformed}</div>
      <div>Тип компоненту: {injured.blodType}</div>

      <h5>Інфузії</h5>
      <div>Ким виконано: {injured.fluidWhoPerformed}</div>
      <div>Назва: {injured.fluidName}</div>
      <div>Шлях введення: {injured.fluidRoute}</div>
      <div>Об'єм: {injured.fluidDose}</div>

      <h5>Медикаменти</h5>
      <div>Ким виконано: {injured.medicationsWhoPerformed}</div>
      <div>Назва: {injured.medicationsName}</div>
      <div>Шлях введення: {injured.medicationsRoute}</div>
      <div>Дозування: {injured.medicationsDose}</div>

      <p></p>
      <div>Ким заповненно: {injured.responderName}</div>
      <div>Позивний: {injured.responderNickname}</div>
      <div>Номер екіпажу: {injured.responderSquadNumber}</div> */}

      <div>Заповнював: {owner.firstName} {owner.lastName} ({owner.nickName})</div>
      <div>Роль в екіпажі: {owner.crewRole}</div>
      <div>Дата надсилання інформації: {new Date(injured.dateCreate).toLocaleDateString()}</div>
    </>
  )
}