import React from "react";

export const SelectInput = ({errors, fieldName, label, value, onChange, arrayOptions }) => {
  return (
    <>
      <label>{label}<span style={{color:'red'}}>{errors?.[fieldName] && <span> {errors?.[fieldName]?.message || 'Error!'}</span>}</span></label>
      <select className="browser-default" value={value} onChange={onChange} defaultValue={'DEFAULT'}>
      <option value="DEFAULT" disabled>Виберіть</option>
        {arrayOptions.map((item, index)=>{
         return <option key={index}>{item}</option>
        })}
      </select>
      {/* <div style={{color:'red'}}>{errors?.[fieldName] && <p> {errors?.[fieldName]?.message || 'Error!'}</p>}</div>         */}
    </>
  );
};
