import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import { Controller, FormProvider, useForm } from "react-hook-form";
// import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'


export const RegisterPage = () =>{
    const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));


    const message = useMessage()
    const {request} = useHttp()
    const navigate = useNavigate();

    // const registerHandler =  async () =>{
    //     try{    
    //         const data = await request('/api/auth/register', 'POST', JSON.stringify(data))
    //         message(data.message)
    //     } catch (e){}
    // }

    // const [value, setValue] = useState()


        const methods = useForm({mode:'onChange'})

        const { control, handleSubmit, formState: { errors } } = methods
        const registerHandler = async (data) => {
        data.email = data.email.toLowerCase()  
          try{    
            const anotherdata = await request('/api/auth/register', 'POST', data)
            message(anotherdata.message)
            await delay(2500);
            navigate(`/`)
        } catch (e){
            message(e.message)
        }
          // fetch('/api', data)
        }

    return(
        
        <FormProvider {...methods}>
            
            <div className="row">
            <h1>Реєстрація</h1>

            <label>Ім'я</label>
            <Controller
            rules={{ required: 'Поле обов`язкове'}}
            name='firstName'
            control={control}
            render={({ field: { value, onChange } }) => (
                <><input 
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.firstName && <p> {errors?.firstName?.message || 'Error!'}</p>}</div>
                </>
            )}/>

            <label>Прізвище</label>
            <Controller
            rules={{ required: 'Поле обов`язкове'}}
            name='lastName'
            control={control}
            render={({ field: { value, onChange } }) => (
                <><input
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.lastName && <p> {errors?.lastName?.message || 'Error!' }</p>}</div>
                </>
            )}/>

            <label>Позивний</label>
            <Controller
            rules={{ required: 'Поле обов`язкове'}}
            name='nickName'
            control={control}
            render={({ field: { value, onChange } }) => (
                <><input
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.nickName && <p> {errors?.nickName?.message || 'Error!' }</p>}</div>
                </>
            )}/>

            <label>Номер телефона у форматі +38-номер</label>
            <Controller
            rules={{ required: 'Поле обов`язкове',
                    minLength: {value: 10, message: 'Введіть коректний номер'}}}
            name='phoneNumber'
            control={control}
            render={({ field: { value, onChange } }) => (

                <><PhoneInput
                country={'ua'}
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.phoneNumber && <p> {errors?.phoneNumber?.message || 'Error!' }</p>}</div>
                </>
            )}/>

            

            <label>Роль в екіпажі</label>
            <Controller
            rules={{ required: 'Поле обов`язкове'}}
            name='crewRole'
            control={control}
            render={({ field: { value, onChange } }) => (
                <select className="browser-default"
                value={value}
                onChange={onChange}>
                    <option disabled selected>Виберіть</option>
                    <option>Медик</option>
                    <option>Парамедик</option>
                    <option>Водій</option>
                    {/* <option>Поранений</option> */}
                </select>
            )}/>

            <label>Email/Логін</label>
            <Controller
            rules={{ required: 'Поле обов`язкове'}}
            name='email'
            control={control}
            render={({ field: { value, onChange } }) => (
                <><input
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.email && <p> {errors?.email?.message || 'Error!' }</p>}</div>
                </>
            )}/>

            <label>Пароль</label>
            <Controller
            rules={{ required: 'Поле обов`язкове',
                    minLength: {value: 6, message: 'Занадто короткий пароль'}}}
            name='password'
            control={control}
            render={({ field: { value, onChange } }) => (
                <><input
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.password && <p> {errors?.password?.message || 'Error!' }</p>}</div>
                </>
            )}/>
            </div>

        <button className=" btn-large" onClick={handleSubmit(registerHandler)}>Зареєструватися</button>
        {/* <Button onClick={handleSubmit(onRegister)}>Зареєструватися</Button > */}
        </FormProvider>
        // <div className='row'>
        //     <h1>Реєстрація</h1>
        //     <form onSubmit={handleSubmit(onSubmit)}>

        //     {/* <PhoneInput
        //         value={value}
        //         {...register('phoneNumber',{
        //             required: 'Поле обов`язкове'
        //         })}
        //         onChange={phone => setValue(phone)}
        //     /> */}
            
        //         <label>І'мя</label>
        //             <input {...register('firstName',{
        //                     required: 'Поле обов`язкове'
        //                 })}/>
        //         <div>{errors?.firstName && <p> {errors?.firstName?.message || 'Error!'}</p>}</div>


        //         <label>Прізвище</label>
        //         <input {...register('lastName',{
        //                     required: 'Поле обов`язкове'
        //                 })}/>
        //         <div>{errors?.lastName && <p> {errors?.lastName?.message || 'Error!'}</p>}</div>


        //         <label>Позивний</label>
        //         <input {...register('nickName',{
        //                     required: 'Поле обов`язкове',
        //                 })}/>
        //         <div>{errors?.nickName && <p> {errors?.nickName?.message || 'Error!'}</p>}</div>

        //         <label>Номер телефона у форматі +38-номер</label>
        //         <input type='text' {...register('phoneNumber',{
        //                     required: 'Поле обов`язкове',
        //                     minLength: {value: 9, message: 'Введіть коректний номер'},
        //                     maxLength: {value: 16, message: 'Введіть коректний номер'}
        //                 })}/>
        //         <div>{errors?.phoneNumber && <p> {errors?.phoneNumber?.message || 'Error!'}</p>}</div>


        //         <label>Роль в екіпажі</label>                
        //         <select className="browser-default"
        //                 {...register('crewRole',{
        //                     required: 'Поле обов`язкове'
        //                 })}><option value="" disabled selected>Виберіть</option>
        //                 <option>Медик</option>
        //                 <option>Парамедик</option>
        //                 <option>Водій</option>
        //                 </select>
        //         <div>{errors?.crewRole && <p> {errors?.crewRole?.message || 'Error!'}</p>}</div>
                
 
        //         <label>Email/Логін</label>
        //         <input type='text'
        //              {...register('email',{
        //                     required: 'Поле обов`язкове'
        //                 })}/>
        //         <div s>{errors?.email && <p> {errors?.email?.message || 'Error!'}</p>}</div>

        //         <label>Пароль</label>
        //         <input type='text'
        //                 {...register('password',{
        //                     required: 'Поле обов`язкове',minLength: {value: 8, message: 'Мінімум 8 символів'
        //                     }
        //                 })}/>
        //         <div>{errors?.password && <p> {errors?.password?.message || 'Error!'}</p>}</div> 

        //         <button >Зареєструватися</button>
        //     </form>
        // </div>
    )
}
