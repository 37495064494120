import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHttp } from "../hooks/http.hook";
import { AuthContext } from "../context/AuthContext";
import { Loader } from "../components/Loader";
import { LinkCard } from "../components/LinkCard";
import { SwitchComponent } from "../components/InjuredsSwitchInput";
import { FormProvider, useForm } from "react-hook-form";
import { SelectInput } from "../components/SelectInput";
import { Controller } from "react-hook-form";
import { useMessage } from "../hooks/message.hook";
import { InjuredsList } from '../components/InjuredsList'

export const StatisticPage = () => {
  const[statisticData, setStatisticData] = useState([])
  const[statisticType, setStatisticType] = useState({})

  const message = useMessage()
  const { loading, request } = useHttp();
  const { token } = useContext(AuthContext);
  const auth = useContext(AuthContext);

  const logoutHandler = () => {
    auth.logout();
  };

  const methods = useForm({ mode: "onBlur" });
  const {
    control,
    resetField,
    handleSubmit,
    getValues,
    getFieldState,
    formState: { errors },
  } = methods;

  const statisticFormІnformation = {
    label: "Вивести статистику за*",
    fieldName: "StatisticPeriod",
    type: "select",
    arrayOptions: ["День", "Місяць", "Рік", "Проміжок часу"],
    rules: { required: "Поле обов`язкове" },
  };
  const dayStatistic = {
    label: "Виберіть день за який хочете побачити статистику *",
    fieldName: "statisticDay",
    type: "input",
    dataType: "date",
    rules: { required: "Поле обов`язкове" },
  };
  const monthStatistic = {
    label: "Виберіть довільний день в тому місяці за який хочете побачити статистику*",
    fieldName: "statisticMonth",
    type: "input",
    dataType: "date",
    rules: { required: "Поле обов`язкове" },
  };
  const yearStatistic = {
    label: "Виберіть довільний день в тому році за який хочете побачити статистику*",
    fieldName: "statisticYear",
    type: "input",
    dataType: "date",
    rules: { required: "Поле обов`язкове" },
  };
  const periodStatistic = [{
    label: "Виберіть початкову дату для статистики*",
    fieldName: "statisticStartDay",
    type: "input",
    dataType: "date",
    rules: { required: "Поле обов`язкове" },
  },
    {label: "Виберіть кінцеву дату для статистики *",
    fieldName: "statisticEndDay",
    type: "input",
    dataType: "date",
    rules: { required: "Поле обов`язкове" },
  }
]


  const pressHandler = async (data) =>{
    try{const fetched = await request('/api/statistic', 'POST', selectStatisticType(data), {
        Authorization: `Bearer ${auth.token}`
      })
      setStatisticData(fetched)
      setStatisticType(selectStatisticType(data)) 
    } catch (e) {
      message(e.message)
      // logoutHandler()
    }
  }
  // ,[auth.token, message, request, statisticData, statisticType, setStatisticType, selectStatisticType, setStatisticData])


  const selectStatisticType = (data) =>{
    switch (data.StatisticPeriod) {
      case 'День':
        return({statisticType:'day', statisticDay:data.statisticDay })
        break
      case 'Місяць':
        return({statisticType:'month', statisticMonth:data.statisticMonth })
        break
      case 'Рік':
        return({statisticType:'year', statisticYear:data.statisticYear })
        break
      case 'Проміжок часу':
        return({statisticType:'dayToDay', statisticStartDay:data.statisticStartDay, statisticEndDay:data.statisticEndDay })
        break
    }
  }

  const switchForm = (period) => {
    switch (period) {
      case "День":
        return(<>
          {SwitchComponent(dayStatistic,errors)}
          {/* / {setStatisticType(selectStatisticType(data))} */}
          <button className=" btn-large" onClick={handleSubmit(pressHandler)}>Отримати статистику</button>
          </>)
      case "Місяць":
        return(<>
        {SwitchComponent(monthStatistic,errors)}
        <button className=" btn-large" onClick={handleSubmit(pressHandler)}>Отримати статистику</button>
        </>)
      case "Рік":
        return(<>
        {SwitchComponent(yearStatistic,errors)}
        <button className=" btn-large" onClick={handleSubmit(pressHandler)}>Отримати статистику</button>
        </>)
      case "Проміжок часу":
        return(<>
              {SwitchComponent(periodStatistic[0], errors)}
              {SwitchComponent(periodStatistic[1],errors)}
              <button className=" btn-large" onClick={handleSubmit(pressHandler)}>Отримати статистику</button>
              </>)
              // {SwitchComponent(BCarray[0], errors)}
    }
  };


  return (
    <>
      {!loading}
      <FormProvider {...methods}>
        <h1>Статистика</h1>
        <Controller
                rules={{ required: 'Поле обов`язкове'}}
                name='StatisticPeriod'
                type="text"
                control={control}
                render={({ field: { value, onChange }}) => (
                    <>
                    <label>{statisticFormІnformation.label}<span style={{color:'red'}}>{errors?.StatisticPeriod && <span> {errors?.StatisticPeriod?.message || 'Error!'}</span>}</span></label>
                    <select className="browser-default" 
                    value={value} 
                    onChange={onChange} 
                    defaultValue={'DEFAULT'}
                    >
                      <option value="DEFAULT" disabled>Виберіть</option>
                      {statisticFormІnformation.arrayOptions.map((item, index)=>{
                      return <option key={index}>{item}</option>
                     })}</select>
                     {switchForm(value)}
                    </>
            )}/>  
      </FormProvider>
      <InjuredsList injureds={statisticData}/>
    </>
  );
};
