import React from "react";
import { Link } from "react-router-dom";

export const InjuredsList = ({ injureds }) => {
  if (!injureds.length) {
    return <p className="center">Звіти відсутні</p>;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>№</th>
          <th>ПІБ</th>
          {/* <th>Дата надсилання</th> */}
          <th>№ екіпажу</th>
          <th>Детально</th>
        </tr>
      </thead>

      <tbody>
        {injureds.map((injured, index) => {
          return (
            <tr key={injured._id}>
              <td>{index + 1}</td>
              <td>{injured.injuredFormInjuredInformation[0].ValueField}</td>
              {/* 
              <td>{new Date(injured.date).getFullYear() + '-'}
              // {new Date(injured.date).getFullYear() + '-'} 
              {new Date(injured.date).getMonth() +1 + '-'}
              {new Date(injured.date).getDate()}</td>
              {new Date(injured.date).toLocaleDateString()}
               */}
              <td>{injured.squadInfo[0].ValueField}</td>
              <td>
                <Link to={`/detail/${injured._id}`}>Відкрити</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
