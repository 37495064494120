import React, { useContext } from "react";
import {NavLink} from "react-router-dom";
import {AuthContext} from "../context/AuthContext";
import M from "materialize-css/dist/js/materialize.min.js";

export const Navbar = () => {
    const auth = useContext(AuthContext)

    const logoutHandler = event => {
        event.preventDefault()
        auth.logout()
    }
    document.addEventListener('click', function() {
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, {});
      });


    const statisticPageView = () => {
        if (auth.userPermissions.includes('statisticsViewer'))
            return(<li><NavLink to='/statistic'>Статистика</NavLink></li>)
    }
   
    return(
        <nav>
            <div className="nav-wrapper teal darken-2" >
                <a href="/" className="brand-logo" style={{ margin: '0 0 0 1rem' }}>Госпітальєри</a>
                <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                <ul id="nav-mobile" className="right hide-on-med-and-down">
                    <li><NavLink to='/newInjured'>Звіт про надану допомогу</NavLink></li>
                    <li><NavLink to='/injureds'>Список поранених</NavLink></li>
                    {statisticPageView()}
                    <li><a href='/' onClick={logoutHandler}>Вийти</a></li>
                </ul>
            </div>
            <ul  className="sidenav" id="mobile-demo">
            <li><a href="/newInjured">Звіт про надану допомогу</a></li>
            <li><a href="/injureds">Список поранених</a></li>
            {statisticPageView()}
            <li><a href='/' onClick={logoutHandler}>Вийти</a></li>
        </ul>
        </nav>
    )
}