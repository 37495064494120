import React, {useContext } from "react";
import { useHttp } from "../hooks/http.hook";
import { useMessage } from "../hooks/message.hook";
import { AuthContext } from "../context/AuthContext";
// import { Link } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export const AuthPage = () => {
  const auth = useContext(AuthContext);
  const {request } = useHttp();

  const message = useMessage()
  const methods = useForm({mode:'onChange'})
  const { control, handleSubmit, formState: { errors } } = methods
  const loginHandler = async (data) => {
    data.email = data.email.toLowerCase()
    try {
      const anotherdata = await request("/api/auth/login", "POST", data);
      auth.login(anotherdata.token, anotherdata.userId, anotherdata.permissions);
    } catch (e) {
      message(e.message)
    }
  };


  return (
      <FormProvider {...methods}>
            <div className="row">
            <h1>Автентифікація</h1>
              
              <label>Email</label>
              <Controller
                rules={{ required: 'Поле обов`язкове'}}
                name='email'
                type="text"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <><input 
                    placeholder="Введіть email"
                    value={value}
                    onChange={onChange}
                    />
                    <div style={{color:'red'}}>{errors?.email && <p> {errors?.email?.message || 'Error!' }</p>}</div>
                    </>
            )}/>
          
            <label>Пароль</label>
            <Controller
            rules={{ required: 'Поле обов`язкове',
                    minLength: {value: 6, message: 'Занадто короткий пароль'}}}
            name='password'
            control={control}
            render={({ field: { value, onChange } }) => (
                <><input
                placeholder="Введіть пароль"
                type="password"
                value={value}
                onChange={onChange}
                />
                <div style={{color:'red'}}>{errors?.password && <p> {errors?.password?.message || 'Error!' }</p>}</div>
                </>
            )}/>
            
            <div className="card-action">
            <button className="btn yellow darken-4" onClick={handleSubmit(loginHandler)}>Ввійти</button>
            <Link to={`/register`}><button className="btn gray" >Зареєструватися</button></Link>
            </div>
            </div>
      </FormProvider>
      
  );
};
