import React from "react";
import { TextInput } from "../components/TextInput";
import { SelectInput } from "../components/SelectInput";
import { TextAreaInput } from "../components/TextAreaInput";

import { Controller } from "react-hook-form";

const INJURED_SWITCH_COMPONENTS_TYPE = {
    INPUT: 'input',
    SELECT: 'select',
    TEXTAREA: 'textarea'
}

export const SwitchComponent = ({
  label,
  type,
  rules,
  fieldName,
  dataType,
  arrayOptions,
  onChange,
  clas,
}, errors) => {
  const props = {
    label,
    type,
    rules,
    dataType,
    name: fieldName,
    arrayOptions,
    onChange,
    clas,
  };



  switch (type) {
    case INJURED_SWITCH_COMPONENTS_TYPE.INPUT:
      props.render = ({ field: { value, onChange } }) => (<>
        <TextInput errors={errors} fieldName={fieldName} dataType={dataType} label={label} value={value} onChange={onChange} clas={clas} />
      </>
      );
      break;
    case INJURED_SWITCH_COMPONENTS_TYPE.SELECT:
      props.render = ({ field: { value, onChange, } }) => (
          <SelectInput errors={errors} fieldName={fieldName} label={label} value={value} onChange={onChange}  arrayOptions={arrayOptions}/>
      );
      break;
    case INJURED_SWITCH_COMPONENTS_TYPE.TEXTAREA:
        props.render = ({ field: { value, onChange } }) => (<>
          <TextAreaInput errors={errors} fieldName={fieldName} dataType={dataType} label={label} value={value} onChange={onChange} />
        </>
        );
      break;
    default:
      console.log(`Component type - ${type} is not supported`);
      break;
  }
  return <>
  <Controller {...props} 
  />

  </>
};

export const InjuredList = ({informationsArray, errors}) => {
  return informationsArray.map((item ) => SwitchComponent(item, errors));
};
