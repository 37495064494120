import React from "react";

export const TextInput = ({fieldName, errors, dataType, label, value, onChange }) => {
  return (
    <>
      <label>{label}<span style={{color:'red'}}>{errors?.[fieldName] && <span> {errors?.[fieldName]?.message || 'Error!'}</span>}</span></label>
      <input type={dataType} value={value} onChange={onChange} />
      {/* <div style={{color:'red'}}>{errors?.[fieldName] && <p> {errors?.[fieldName]?.message || 'Error!'}</p>}</div>         */}
    </>
  );
};
